import "./App.css";

function Footer() {
  return (
    <div className="container-fluid">
      <p className="footer">
        Copyright 2023 - proudly coded by Jen Luchka | Modest Design Co.
      </p>
    </div>
  );
}
export default Footer;
